import React, { useState, useEffect } from "react";
import { Box, Grid, Typography, Button, IconButton } from "@mui/material";
import ContactButton from "../floatingcontactus/contactbutton";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import cred1 from "../../assets/cred1.png";
import cred2 from "../../assets/cred2.png";
import cred3 from "../../assets/cred3.png";
import cred4 from "../../assets/cred4.png";
import cred5 from "../../assets/cred5.png";
import cred6 from "../../assets/cred6.png";
import credfinalwebp from "../../assets/credfinalwebp.webp";

const boxesData = [
  {
    image: cred1,
    title: "Renewable Energy ",
    description:
      "We finance projects in solar, wind, hydro, and other renewable energy sources, accelerating the transition to clean energy.",
  },
  {
    image: cred2,
    title: "Waste to CBG",
    description:
      "Transforming waste into Compressed Biogas (CBG) for clean energy production, reducing environmental impact and fostering circular economies. ",
  },
  {
    image: cred3,
    title: "EV and EV Ecosystem",
    description:
      "Supporting the electric vehicle (EV) revolution and its ecosystem, including charging infrastructure and related technologies, to reduce carbon emissions from transportation.",
  },
  {
    image: cred4,
    title: "Forestation and Decarbonization",
    description:
      "Investing in reforestation projects and decarbonization initiatives to restore ecosystems and mitigate climate change effects.",
  },
  {
    image: cred5,
    title: "Manufacturing",
    description:
      "Financing sustainable manufacturing processes and technologies to minimize environmental footprint and promote eco-friendly production methods. ",
  },
  {
    image: cred6,
    title: "Supply Chain",
    description:
      "Partnering with businesses committed to sustainable supply chain practices, ensuring transparency and accountability throughout the value chain.",
  },
];

const AlterCredit = () => {
  const openForm = () => {
    window.open("https://forms.gle/D5vYEmFh73hbj3ME8", "_blank"); // Opens in a new tab
  };

  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrevious = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + boxesData.length) % boxesData.length
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % boxesData.length);
    }, 5000); 
  
    return () => clearInterval(interval);
  }, []);
  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % boxesData.length);
  };

  const currentBox = boxesData[currentIndex];
  return (
    <Box style={{ overflowX: 'hidden' }}>
      <Grid container>
        <Grid item xs={12}>
          <Box style={{ position: "relative" }}>
            <Box
              sx={{
                width: "100%",
                height: {
                  md: "100vh",
                  xs: "90vh",
                },
                overflow: "hidden",
                backgroundColor: "#3b3939",
              }}
            >
              <img
                alt="carousel"
                src={credfinalwebp}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  opacity: 0.5,
                }}
              />
            </Box>
            <Box
              style={{
                position: "absolute",
                top: "35%",
                left: "5%",
                // transform: "translate(-50%, -50%)",
                color: "white",
                fontWeight: "bold",
              }}
            >
              <Typography
                sx={{
                  fontSize: {
                    md: "1.8rem",
                    xs: "2rem",
                  },
                  color: "#34BC56",
                  fontWeight: 900,
                }}
              >
                AlterCredit
              </Typography>
              <Typography
                sx={{
                  fontSize: {
                    md: "5.5rem",
                    xs: "3rem",
                  },
                  color: "#ededed",
                  fontFamily: "serif",
                  fontWeight: 900,
                  lineHeight: {
                    md: "100px",
                    xs: "50px",
                  },
                }}
              >
                Empowering the <br /> Green Transition
              </Typography>
              <Button
                variant="contained"
                color="primary"
                endIcon={<ArrowForwardIcon />}
                sx={{
                  mt: 5, // margin top
                  fontSize: {
                    md: "1.2rem",
                    xs: ".8rem",
                  }, // increased font size
                  padding: "15px 25px", // added padding
                  fontWeight: "bold",
                  backgroundColor: "#34BC56",
                  borderRadius: "40px", // rounded corners
                  ":hover": {
                    backgroundColor: "#2DA047",
                  },
                }}
                onClick={() => {
                  openForm();
                }}
              >
                Reach out to us
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{
            height: {
              xs: "55vw",
              md: "12vw",
            },
            width: "100vw",
            fontSize: { xs: "1.2rem", sm: "1.2rem" },
            fontWeight: 500,
            padding: {
              xs: 2,
              md: 10,
            },
            paddingTop: {
              xs: "30px", // Add paddingTop of 10px for extra-small screens
              md: 10,
            },
            pb: {
              xs: "30px", // Add paddingTop of 10px for extra-small screens
              md: 10,
            },
            backgroundColor: "#EBFFF5",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            whiteSpace: "pre-wrap", // This ensures text wrapping
            wordWrap: "break-word", // This ensures text wrapping for long words
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontSize: {
                xs: "4vw",
                sm: "3vw", 
                md: "2vw", 
                lg: "1.5vw", 
              },
              textAlign: "center", 
            }}
          >
            At Alter Credit, we're committed to fostering a sustainable future by empowering businesses driving the green transition. Through our innovative green lending solutions, we enable industries to transition towards net zero emissions while thriving economically.
          </Typography>
        </Box>
      </Grid>
     
      <Grid
        item
        xs={12}
        sx={{
          p: {
            xl: "66px",
            md: "66px",
            xs: "0px",
          },
          height: {
            md: "30vh",
            // xs: "85vh",
          },
          
        }}
      >
        <Box
          sx={{
            p: {
              // md: "70px",
              xs: "20px",
            },
            mt: {
              xs: 1,
              sm: 0,
            },
            
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: { xs: "1.2rem", sm: "1.2rem" },
              fontWeight: "bold",
              width: "100%",
              color: "#3CB769",
            }}
          >
            OUR SERVICES
          </Typography>
          <Typography
            variant="h3"
            sx={{
              mt: 1,
              fontSize: { xs: "1.2rem", sm: "1.7rem" },
              fontWeight: "bold",
              fontFamily: "sans-serif",
              width: "100%",
              color: "black",
            }}
          >
            Our Green Lending Focus Areas:
          </Typography>
        </Box>
      </Grid>
      <Box
        sx={{
          backgroundColor: "#EBFFF5",
          width: "100%",
          p: { xs: 0, sm: 4 },
          borderRadius: "10px",
          mt: {
            md: "0px",
            xs: "10px ",
          },
          pt: {
            md: "30px",
            xs: "15px ",
          },
          // height: "80%",
        }}
      >
        {/* <Box sx={{ pb: { xs: 2, sm: 0 } }}>
          <SwiperComponent />
        </Box> */}

        <Box sx={{ maxHeight: "80vh", overflow: "hidden", margin: "auto" }}>
          <Grid
            container
            sx={{
              ml: {
                md: "80px",
                xs: "0px",
              },
              background: "rgba(255, 255, 255, 0.501)",
              p: "20px",
              mb: "20px",
            }}
          >
            <Grid
              item
              md={1}
              xs={12}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <IconButton
                onClick={handlePrevious}
                sx={{
                  backgroundColor: "#34BC56",
                  color: "black",
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "grid",
                  },
                }}
              >
                <ChevronLeftIcon />
              </IconButton>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                paddingRight: "5px",
                alignItems: "center",
              }}
            >
              <img
                src={currentBox.image}
                alt={currentBox.title}
                style={{ width: "75%", height: "70%" }}
              />
            </Grid>
            <Grid
              item
              md={7}
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                paddingRight: "5px",
              }}
            >
              <Typography
                sx={{
                  color: "#34BC56",
                  fontSize: "1.6rem",
                  textTransform: "uppercase",
                  fontWeight: 900,
                  mt: "10px",
                }}
              >
                {currentBox.title}
              </Typography>
              <Typography
                sx={{
                  mt: "10px",
                  fontWeight: 500,
                  fontSize: "1rem",
                  fontFamily: "sans-serif",
                  color: "#404040",
                }}
              >
                {currentBox.description}
              </Typography>
            </Grid>
            <Grid
              item
              md={1}
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "0px",
                justifyContent: "space-between",
              }}
            >
              <IconButton
                onClick={handlePrevious}
                sx={{
                  backgroundColor: "#34BC56",
                  color: "black",
                  display: {
                    xs: "grid",
                    sm: "grid",
                    md: "none",
                  },
                }}
              >
                <ChevronLeftIcon />
              </IconButton>
              <IconButton
                onClick={handleNext}
                sx={{ backgroundColor: "#34BC56", color: "black" }}
              >
                <ChevronRightIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <ContactButton redirectUrl="https://forms.gle/D5vYEmFh73hbj3ME8" />
    </Box>
  );
};

export default AlterCredit;
