import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import altadvisorywebp from "../../assets/altadvisorywebp.webp";
import AdvisorySwiper from "./AdvisorySwiper";
import ContactButton from "../floatingcontactus/contactbutton";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import advi1 from "../../assets/advi1.png";
import advi2 from "../../assets/advi2.png";
import advi3 from "../../assets/advi3.png";
import advi4 from "../../assets/advi4.png";
import advi5 from "../../assets/advi5.png";
import advi6 from "../../assets/advi6.png";
import advi7 from "../../assets/advi7.png";
import advi8 from "../../assets/advi8.png";
const boxesData = [
  {
    image: advi1,
    title: "Growth Advisory",
    description:
      "Discover and capitalize on global marketplace opportunities to achieve long-term sustainable growth with our Growth Advisory services. We provide market analysis and strategic planning to help organizations expand beyond normal limits, identifying opportunities for innovation and expansion while aligning with their sustainability goals.",
  },
  {
    image: advi2,
    title: "Capital Advisory",
    description:
      "Unlock the financial resources necessary to fuel your sustainable and growth initiatives with our Capital Advisory services. Specializing in mergers and acquisitions, we connect organizations with investors and funding opportunities that align with their values and objectives, ensuring that capital is deployed efficiently, clinically and ethically.",
  },
  {
    image: advi3,
    title: "Energy Transition",
    description:
      "Navigate towards a cleaner, more sustainable energy future with our Energy Transition services. Whether implementing renewable energy solutions or optimizing energy efficiency, we assist organizations in minimizing their environmental footprint while maximizing operational performance.",
  },
  {
    image: advi4,
    title: "Decarbonization",
    description:
      "Lead the charge towards a carbon-neutral future with our Decarbonization services. We work closely with organizations to develop strategies for reducing carbon emissions across all operational phases, ensuring a sustainable and responsible approach.",
  },
  {
    image: advi5,
    title: "Sustainable Manufacturing",
    description:
      "Innovate and optimize your manufacturing processes with our Sustainable Manufacturing services. From materials sourcing to production techniques, we help organizations minimize waste, reduce environmental impacts, and enhance operational efficiency.",
  },
  {
    image: advi6,
    title: "Sustainable Supply Chain",
    description:
      "Build a resilient and ethical supply chain with our Sustainable Supply Chain services. We assist organizations in identifying and mitigating environmental and social risks within their supply chains, fostering transparency, accountability, and sustainability throughout the procurement process.",
  },
  {
    image: advi7,
    title: "Mergers and Acquisitions Advisory",
    description:
      "Buy and sell effectively to maximize your value through our Strategic and Systemic approach to Mergers and Acquisitions. Discover the right buyers and sellers through our ecosystem, ensuring a smooth and advantageous transaction process.",
  },
  {
    image: advi8,
    title: "Business Turnaround & Breakthrough",
    description:
      "Achieve significant business breakthroughs with our Business Turnaround services. We provide strategic insights and actionable solutions to revitalize your business, driving substantial improvements in performance and positioning you for future success.",
  },
];

const AlterAdvisory = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrevious = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + boxesData.length) % boxesData.length
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % boxesData.length);
  };

  // time out for slider
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % boxesData.length);
    }, 5000); // Change content every 5 seconds

    return () => clearInterval(interval);
  }, [boxesData.length]);

  const currentBox = boxesData[currentIndex];
  // ---------------------------------open form in new tab---------------------------------
  const openForm = () => {
    window.open("https://forms.gle/5BcoQg9X21zsqdVR8", "_blank"); // Opens in a new tab
  };

  return (
    <Box style={{ overflowX: 'hidden' }}>
      <Grid container>
        <Grid item xs={12}>
          <Box style={{ position: "relative" }}>
            <Box
              sx={{
                width: "100%",
                height: {
                  md: "100vh",
                  xs: "90vh",
                },
                overflow: "hidden",
                backgroundColor: "#3b3939",
              }}
            >
              <img
                src={altadvisorywebp}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  opacity: 0.5,
                }}
              />
            </Box>
            <Box
              style={{
                position: "absolute",
                top: "35%",
                left: "5%",
                // transform: "translate(-50%, -50%)",
                color: "white",
                fontWeight: "bold",
              }}
            >
              <Typography
                sx={{
                  fontSize: {
                    md: "1.8rem",
                    xs: "2rem",
                  },
                  color: "#02a84a",
                  fontWeight: 900,
                }}
              >
                AlterAdvisory
              </Typography>
              <Typography
                sx={{
                  fontSize: {
                    md: "5.5rem",
                    xs: "3rem",
                  },
                  color: "#ededed",
                  fontFamily: "serif",
                  fontWeight: 900,
                  lineHeight: {
                    md: "100px",
                    xs: "50px",
                  },
                }}
              >
                Leading the Way to <br /> Sustainable <br /> Growth
              </Typography>
              <Button
                variant="contained"
                color="primary"
                endIcon={<ArrowForwardIcon />}
                sx={{
                  mt: 2, // margin top
                  fontSize: {
                    md: "1.2rem",
                    xs: ".8rem",
                  }, // increased font size
                  padding: "15px 25px", // added padding
                  fontWeight: "bold",
                  backgroundColor: "#34BC56",
                  borderRadius: "40px", // rounded corners
                  ":hover": {
                    backgroundColor: "#2DA047",
                  },
                }}
                onClick={() => {
                  openForm();
                }}
              >
                Reach out to us
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{
            height: {
              xs: "84vw",
              md: "15vw",
            },
            width: "100vw",
            fontSize: { xs: "1.2rem", sm: "1.2rem" },
            fontWeight: 500,
            padding: {
              xs: 2,
              md: 10,
            },
            paddingTop: {
              xs: "30px", // Add paddingTop of 10px for extra-small screens
              md: 10,
            },
            pb: {
              xs: "30px", // Add paddingTop of 10px for extra-small screens
              md: 10,
            },
            backgroundColor: "#EBFFF5",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            whiteSpace: "pre-wrap", // This ensures text wrapping
            wordWrap: "break-word", // This ensures text wrapping for long words
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontSize: {
                xs: "4vw", // font size for extra-small screens (phones)
                sm: "3vw", // font size for small screens (tablets)
                md: "2vw", // font size for medium screens (desktops)
                lg: "1.5vw", // font size for large screens
              },
              textAlign: "center", // Center-align the text
            }}
          >
            {/* Place your description here */}
            Altercarbon Advisor is at the forefront of pioneering sustainable
            solutions for the future of growing companies. As a leading advisory
            and consulting firm, our mission is to empower our clients to
            achieve extraordinary results and expand their market
            presence globally by integrating sustainable practices within their
            industries. 
            <br />
            At Altercarbon, we believe in fostering innovation, driving growth,
            and shaping a new future that aligns harmoniously with environmental
            stewardship.
          </Typography>
        </Box>
      </Grid>
      <Box
        sx={{
          height: {
            md: "50vh",
            xs: "80vh",
          },
          pt: {
            xs: "40px",
          },
          width: "100%",
          backgroundColor: "#202220",
        }}
      >
        <Grid
          container
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            md={6}
            xs={12}
            sx={{
              color: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "70%",
                backgroundColor: "#515152",
                padding: "20px",
                borderRadius: "8px",
                border: "1px solid grey",
              }}
            >
              <Typography
                sx={{
                  mt: {
                    md: "-80px",
                    xs: "-66px",
                  },
                  ml: {
                    md: "-55px",
                    xs: "-50px",
                  },
                  fontSize: {
                    md: "4rem",
                    xs: "3rem",
                  },
                  color: "#34BC56",
                  fontWeight: 900,
                }}
              >
                Mission
              </Typography>
              <Typography
                sx={{
                  fontSize: {
                    md: "1.3rem",
                    xs: "1.1rem",
                  },
                }}
              >
                Empowering organizations with strategic guidance for sustainable
                endeavors across industries.
              </Typography>
            </Box>
          </Grid>
          <Grid
            md={6}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: {
                sm: 0,
              },
            }}
          >
            <Box
              sx={{
                width: "70%",
                backgroundColor: "#515152",
                padding: "20px",
                borderRadius: "8px",
                border: "1px solid grey",
              }}
            >
              <Typography
                sx={{
                  mt: {
                    md: "-80px",
                    xs: "-66px",
                  },
                  ml: {
                    md: "-55px",
                    xs: "-50px",
                  },
                  fontSize: {
                    md: "4rem",
                    xs: "3rem",
                  },
                  color: "#34BC56",
                  fontWeight: 900,
                }}
              >
                Vision
              </Typography>
              <Typography
                sx={{
                  fontSize: {
                    md: "1.3rem",
                    xs: "1.1rem",
                  },
                  color: "white",
                }}
              >
                Revolutionizing industries by integrating sustainability into
                every aspect of operation for a better future.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Grid
        item
        xs={12}
        sx={{
          p: {
            xl: "95px",
            md: "70px",
            xs: "15px",
          },
        }}
      >
        <Box
          sx={{
            p: {
              md: "0px",
              xs: "20px",
            },
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: { xs: "1.2rem", sm: "1.2rem" },
              fontWeight: "bold",
              width: "100%",
              color: "#3CB769",
              textTransform: "uppercase",
            }}
          >
            Advisory Practices
          </Typography>
          <Typography
            variant="h3"
            sx={{
              mt: 1,
              fontSize: { xs: "1.2rem", sm: "1.7rem" },
              fontWeight: "bold",
              fontFamily: "sans-serif",
              width: "100%",
              color: "black",
            }}
          >
            Comprehensive Advisory Solutions for Sustainable Growth
          </Typography>
        </Box>
      </Grid>
      <Box
        sx={{
          backgroundColor: "#EBFFF5",
          width: "100%",
          p: { xs: 2, sm: 4 },
          borderRadius: "10px",
          mt: {
            md: "0px",
            xs: "10px",
          },
          // height: "80%",
        }}
      >
        {/* <AdvisorySwiper /> */}

        <Box sx={{ maxHeight: "90vh", overflow: "hidden", margin: "auto" }}>
          <Grid
            container
            sx={{
              ml: {
                md: "80px",
                xs: "0px",
              },
              background: "rgba(255, 255, 255, 0.501)",
              p: "20px",
              mb: "20px",
            }}
          >
            <Grid
              item
              md={1}
              xs={12}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <IconButton
                onClick={handlePrevious}
                sx={{
                  backgroundColor: "#34BC56",
                  color: "black",
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "grid",
                  },
                }}
              >
                <ChevronLeftIcon />
              </IconButton>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                paddingRight: "5px",
                alignItems: "center",
              }}
            >
              <img
                src={currentBox.image}
                alt={currentBox.title}
                style={{ width: "75%", height: "70%" }}
              />
            </Grid>
            <Grid
              item
              md={7}
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                paddingRight: "5px",
              }}
            >
              <Typography
                sx={{
                  color: "#34BC56",
                  fontSize: "1.6rem",
                  textTransform: "uppercase",
                  fontWeight: 900,
                  mt: "10px",
                }}
              >
                {currentBox.title}
              </Typography>
              <Typography
                sx={{
                  mt: "10px",
                  fontWeight: 500,
                  fontSize: "1rem",
                  fontFamily: "sans-serif",
                  color: "#404040",
                }}
              >
                {currentBox.description}
              </Typography>
            </Grid>
            <Grid
              item
              md={1}
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "0px",
                justifyContent: "space-between",
              }}
            >
              <IconButton
                onClick={handlePrevious}
                sx={{
                  backgroundColor: "#34BC56",
                  color: "black",
                  display: {
                    xs: "grid",
                    sm: "grid",
                    md: "none",
                  },
                }}
              >
                <ChevronLeftIcon />
              </IconButton>
              <IconButton
                onClick={handleNext}
                sx={{ backgroundColor: "#34BC56", color: "black" }}
              >
                <ChevronRightIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <ContactButton redirectUrl="https://forms.gle/5BcoQg9X21zsqdVR8" />
    </Box>
  );
};

export default AlterAdvisory;
