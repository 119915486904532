import React, { useState, useEffect } from "react";
import PersonalDetails from "../components/form/form-sections/PersonalDetails";
import ServiceDetails from "../components/form/form-sections/ServiceDetails";
import AddressDetails from "../components/form/form-sections/AddressDetails";
import SiteDetails from "../components/form/form-sections/SiteDetails";
import Processing from "../components/form/form-sections/Processing";
import "../main.css";
import UnitComsumption from "../components/form/form-sections/UnitComsumption";

const MainForm = () => {
  const [step, setStep] = useState(0);
  const [totalSteps] = useState(5);
  const [progress, setProgress] = useState(0);

  const prevStep = (e) => {
    setStep(step - 1);
  };
  const nextStep = (e) => {
    setStep(step + 1);
  };

  const calculateProgress = () => {
    const calculatedProgress = (step / totalSteps) * 100;
    setProgress(calculatedProgress);
  };

  useEffect(() => {
    calculateProgress();
  }, [step]);

  return (
    <>
      <div className="Boxform_wrapper">
        <div className="mainform_wrapper">
          <div className="">
            <form className="form">
              {step < 5 && (
                <div className="form_progress_div">
                  {/* <div className="form_progress_content"></div> */}
                  <div className="progress_container">
                    {/* <div
                      className="progress_number"
                      style={{ left: `${progress - 5}%` }}
                    >
                      {" "}
                      <Image src={RangeImg}/>
                    </div> */}
                    <div className="progress_bar">
                      <div
                        className="progress_active"
                        style={{ width: `${progress}%` }}
                      ></div>
                      {/* <div className="progress_percentage">
                        {progress.toFixed(0)}%
                      </div> */}
                    </div>
                  </div>
                </div>
              )}

              {step === 0 && (
                <PersonalDetails
                  step={step}
                  prevStep={prevStep}
                  nextStep={nextStep}
                />
              )}
              {step === 1 && (
                <ServiceDetails
                  step={step}
                  prevStep={prevStep}
                  nextStep={nextStep}
                />
              )}
              {step === 2 && (
                <AddressDetails
                  step={step}
                  prevStep={prevStep}
                  nextStep={nextStep}
                />
              )}
              {step === 3 && (
                <UnitComsumption
                  step={step}
                  prevStep={prevStep}
                  nextStep={nextStep}
                />
              )}
              {step === 4 && (
                <SiteDetails
                  step={step}
                  prevStep={prevStep}
                  nextStep={nextStep}
                />
              )}
              {step === 5 && (
                <Processing
                  step={step}
                  prevStep={prevStep}
                  nextStep={nextStep}
                />
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainForm;
