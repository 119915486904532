import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import * as yup from "yup";
import { Box, Button, Paper, Typography } from "@mui/material";
import axios from "axios";
import { useDispatch } from "react-redux";
import { updateEmail, updateFirstName, updateLastName, updateMobile, updatePincode } from "../../../store/reducers/formDetailsSlice";

const validateYupSchema = yup.object({
  name: yup.string().required("Please Enter Your First Name"),
  lastName: yup.string().required("Please Enter Your Last Name"),
  phoneNumber: yup
    .string()
    .required("Please Enter Phone Number")
    .matches(/^(?!.*(\d)\1{6})(?![12])\d{10}$/, "Invalid phone number."),
  email: yup
    .string()
    .required("Please Enter Email Address")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Invalid email address"
    ),
});

const PersonalDetails = ({ step, prevStep, nextStep }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const pinCode = sessionStorage.getItem("pinCode");
    dispatch(updatePincode(pinCode));
  }, []);

  const formik = useFormik({
    initialValues: {
      name: "",
      lastName: "",
      email: "",
      phoneNumber: "",
    },
    validationSchema: validateYupSchema,
    onSubmit: (values) => {
      console.log(values);
      dispatch(updateFirstName(values?.name));
      dispatch(updateEmail(values?.email));
      dispatch(updateMobile(values?.phoneNumber));
      dispatch(updateLastName(values?.lastName));
      // addFormData();
      nextStep();
    },
  });
  const addFormData = async () => {
    try {
      const data = {
        pincode: "1234",
        f_name: "urooj",
        l_name: "khan",
        email: "urooj@rfspl2.com",
        mobile: "98889858",
        premise_type: "dsfsdf",
        address: "awra afwf ef",
        electricity_unit: "744",
        electricity_bill: "546",
        date_for_visit: "2017-08-02",
      };
      const res = await axios.post(
        "https://api.altercarbon.in/zoho-bigin/wizard-form",
        data
      );
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Paper elevation={2}>
          <Box
            sx={{
              width: {
                xs: "90vw",
                md: "700px",
              },
              p: {
                xs: "20px 20px 30px 20px",
                md: "50px 100px 60px 100px",
              },
              bgcolor: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              borderRadius: "22px",
            }}
          >
            <Typography
              sx={{
                fontSize: {
                  xs: "18px",
                  md: "30px",
                },
                textAlign: "center",
                fontWeight: 900,
                fontFamily: "sans-serif",
              }}
            >
              What's your name & email?
            </Typography>
            <Typography
              sx={{
                fontSize: {
                  xs: "14px",
                  md: "20px",
                },
                textAlign: "center",
                color: "rgb(40, 147, 238)",
              }}
            >
              We take privacy seriously. No spam!
            </Typography>
            <TextField
              sx={{
                mt: 4,
                width: {
                  xs: "100%",
                  sm: "350px",
                },
              }}
              name="name"
              id="name"
              placeholder="Enter Your First Name"
              variant="outlined"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={Boolean(formik.touched.name && formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
            <TextField
              sx={{
                mt: 2,
                width: {
                  xs: "100%",
                  sm: "350px",
                },
              }}
              name="lastName"
              id="lastName"
              placeholder="Enter Your Last Name"
              variant="outlined"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={Boolean(formik.touched.lastName && formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
            />
            <TextField
              sx={{
                mt: 2,
                width: {
                  xs: "100%",
                  sm: "350px",
                },
              }}
              name="email"
              id="email"
              placeholder="Enter your email address"
              variant="outlined"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={Boolean(formik.touched.email && formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />

            <TextField
              sx={{
                mt: 2,
                width: {
                  xs: "100%",
                  sm: "350px",
                },
              }}
              name="phoneNumber"
              type="tel"
              id="phoneNumber"
              variant="outlined"
              placeholder="Enter your phone number"
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={Boolean(
                formik.touched.phoneNumber && formik.errors.phoneNumber
              )}
              helperText={
                formik.touched.phoneNumber && formik.errors.phoneNumber
              }
              inputProps={{ maxLength: 10 }}
            />

            {step < 7 && (
              <Button
                sx={{
                  p: {
                    xs: "10px 120px",
                    // sm: "10px 100px",
                    md: "12px 130px",
                  },
                  width: {
                    xs: "100%",
                    sm: "350px",
                  },
                  fontSize: "20px",
                  textTransform: "capitalize",
                  color: "white",
                  fontWeight: 900,
                  // backgroundColor: "rgb(225, 28, 28)",
                  mt: 5,
                  whiteSpace: "nowrap",
                }}
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  formik.handleSubmit();
                }}
                variant="contained"
              >
                Continue
              </Button>
            )}
          </Box>
        </Paper>
      </Box>
      <div className="form_step_wrapper"></div>
    </>
  );
};

export default PersonalDetails;
