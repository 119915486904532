"use client";
import React from "react";
import {
  Button,
  Box,
  FormControl,
  Paper,
  Typography,
  Radio,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { updatePremiseType } from "../../../store/reducers/formDetailsSlice";

const validateYupSchema = yup.object({
  utilityProvider: yup.string().required("Please select an option"),
});

const ServiceDetails = ({ step, prevStep, nextStep }) => {
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      utilityProvider: "",
    },
    validationSchema: validateYupSchema,
    onSubmit: (values) => {
      console.log(values);
      dispatch(updatePremiseType(values.utilityProvider));
      nextStep();
    },
  });

  const buttonStyles = (selected) => ({
    mt: "20px",
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
    padding: "5px 20px",
    borderColor: selected ? "rgb(95, 183, 87)" : "rgba(0, 0, 0, 0.23)",
    bgcolor: selected ? "rgba(95, 183, 87, 0.1)" : "transparent",
  });

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper elevation={2}>
        <Box
          sx={{
            width: {
              xs: "90vw",
              md: "700px",
            },
            p: {
              xs: "30px 20px",
              md: "50px 100px",
            },
            bgcolor: "white",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            borderRadius: "22px",
          }}
        >
          <Typography
            sx={{
              fontSize: {
                xs: "18px",
                md: "30px",
              },
              textAlign: "center",
              fontWeight: 900,
              mt: 3,
            }}
          >
            Please select the type of premise where the service will be used:
          </Typography>

          <Box
            sx={{
              mt: 1,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <>
              <FormControl
                component="fieldset"
                sx={{
                  width: {
                    xs: "100%",
                    sm: "350px",
                  },
                }}
              >
                {/* Row house button */}
                <Button
                  variant="outlined"
                  sx={buttonStyles(formik.values.utilityProvider === "Rowhouse")}
                  onClick={() =>
                    formik.setFieldValue("utilityProvider", "Rowhouse")
                  }
                  startIcon={
                    <Radio
                      checked={formik.values.utilityProvider === "Rowhouse"}
                      sx={{ color: "rgb(95, 183, 87)" }} // Radio button color
                    />
                  }
                >
                  Row house
                </Button>

                {/* Bungalow button */}
                <Button
                  variant="outlined"
                  sx={buttonStyles(formik.values.utilityProvider === "Bungalow")}
                  onClick={() =>
                    formik.setFieldValue("utilityProvider", "Bungalow")
                  }
                  startIcon={
                    <Radio
                      checked={formik.values.utilityProvider === "Bungalow"}
                      sx={{ color: "rgb(95, 183, 87)" }} // Radio button color
                    />
                  }
                >
                  Bungalow
                </Button>

                {/* Industrial Facility button */}
                <Button
                  variant="outlined"
                  sx={buttonStyles(formik.values.utilityProvider === "IndustrialFacility")}
                  onClick={() =>
                    formik.setFieldValue("utilityProvider", "IndustrialFacility")
                  }
                  startIcon={
                    <Radio
                      checked={formik.values.utilityProvider === "IndustrialFacility"}
                      sx={{ color: "rgb(95, 183, 87)" }} // Radio button color
                    />
                  }
                >
                  Industrial Facility
                </Button>

                {/* Other (Please Specify) button */}
                <Button
                  variant="outlined"
                  sx={buttonStyles(formik.values.utilityProvider === "Other")}
                  onClick={() =>
                    formik.setFieldValue("utilityProvider", "Other")
                  }
                  startIcon={
                    <Radio
                      checked={formik.values.utilityProvider === "Other"}
                      sx={{ color: "rgb(95, 183, 87)" }} // Radio button color
                    />
                  }
                >
                  Other (Please Specify)
                </Button>

                {formik.touched.utilityProvider &&
                formik.errors.utilityProvider ? (
                  <div style={{ color: "red", marginTop: "10px" }}>
                    {formik.errors.utilityProvider}
                  </div>
                ) : null}

                {/* Submit button */}
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    formik.handleSubmit();
                  }}
                  sx={{
                    p: {
                      xs: "10px 120px",
                      md: "12px 130px",
                    },
                    width: {
                      xs: "100%",
                      sm: "350px",
                    },
                    fontSize: "20px",
                    textTransform: "capitalize",
                    color: "white",
                    fontWeight: 900,
                    mt: 5,
                    whiteSpace: "nowrap",
                  }}
                  type="submit"
                  variant="contained"
                >
                  Continue
                </Button>
              </FormControl>
            </>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default ServiceDetails;
