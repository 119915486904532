import React, { useEffect, useState } from "react";
import { Box, Container, Grid, Button, IconButton } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import Groupwebp from "../../../assets/Groupwebp.webp";
import WindTurbine from "../../../assets/image 104.png";
import solarpanelwebp from "../../../assets/solarpanelwebp.webp";
import handplantwebp from "../../../assets/handplantwebp.webp";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const Section1 = () => {
  const navigate = useNavigate();

  // Data set for dynamic content
  const contentData = [
    {
      heading: "AlterHome: Empowering Housing Societies",
      paragraph:
        "Offering strategic guidance and expertise across various domains, including growth strategies, capital allocation, and energy transition.",
      image: WindTurbine,
      link: "/alter-home",
      buttonText: "Know more",
      buttonLink: "https://forms.gle/ETe4b1KdbsqAAscu8",
    },
    {
      heading: "AlterCredit: Boosting the Eco-Transition",
      paragraph:
        "Alter Credit is devoted to promoting a sustainable future by supporting businesses to drive the green revolution. Through our unique green loan solutions, we help industries shift to net zero emissions while thriving economically.",
      image: handplantwebp,
      link: "/alter-credit",
      buttonText: "Know more",
      buttonLink: "https://forms.gle/D5vYEmFh73hbj3ME8",
    },
    {
      heading:
        "AlterAdvisory: Pioneering Sustainable Solutions for Growing Companies",
      paragraph:
        "We help businesses achieve amazing results and extend their worldwide market presence by integrating sustainable practices into their industries.",
      image: solarpanelwebp,
      link: "/alter-advisory",
      buttonText: "Know more",
      buttonLink: "https://forms.gle/5BcoQg9X21zsqdVR8",
    },
  ];

  const [currentContentIndex, setCurrentContentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentContentIndex(
        (prevIndex) => (prevIndex + 1) % contentData.length
      );
    }, 7000); // Change content every 5 seconds

    return () => clearInterval(interval);
  }, [contentData.length]);

  const content = contentData[currentContentIndex];

  const handlePrevious = () => {
    setCurrentContentIndex(
      (prevIndex) => (prevIndex - 1 + contentData.length) % contentData.length
    );
  };

  const handleNext = () => {
    setCurrentContentIndex((prevIndex) => (prevIndex + 1) % contentData.length);
  };

  return (
    <Box
      sx={{
        width: "100vw",
        backgroundColor: "#ebfff0",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: {
            sm: "100vh",
            md: "100vh",
            xs: "100vh",
          },
        }}
      >
        <img
          src={Groupwebp}
          style={{ width: "100%", height: "100%" }}
          alt="carousel"
        />
      </Box>
      <Container
        maxWidth={"xl"}
        sx={{
          padding: { md: "0 5%" },
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            pt: {
              xl: "0",
              lg: "100px",
              md: "550px",
              sm: "420px",
              xs: "100px",
            },
            pl: {
              md: "0",

              xs: "12vw",
            },
            pr: {
              md: "0",
              xs: "6vw",
            },
          }}
        >
          <section>
            <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
              <div className="mr-auto place-self-center lg:col-span-7">
                <Link to={content.link}>
                  <h1 className="max-w-3xl mb-4 px-2 text-3xl font-serif tracking-tight lg:mb-8 leading-none md:text-4xl xl:text-4xl pr-2 sm:pr-0">
                    <span className="font-bold font-serif">
                      {content.heading.split(":")[0]}:
                    </span>
                    {content.heading.split(":")[1]}
                  </h1>
                </Link>
                <p className="max-w-lg mb-6 px-2 font-light lg:mb-4 md:text-lg lg:text-xl sm:pr-2">
                  {content.paragraph}
                </p>
                <Box
                  sx={{
                    width: "90%",
                    display: "flex",
                    justifyContent: {
                      lg: "flex-start",
                      md: "flex-start",
                      xs: "flex-start",
                    },

                    alignItems: "center",
                  }}
                >
                  <ChevronLeftIcon
                    onClick={handlePrevious}
                    sx={{
                      color: "black", // Retained or updated color
                      fontSize: "1.5rem",
                      mr: 2,
                      display: {
                        xs: "grid",
                        sm: "grid",
                        md: "none",
                      },
                      transform: "translateY(0%)",
                      borderRadius: "40%",
                      backgroundColor: "#34BC56",
                      "&:hover": {
                        backgroundColor: "lightgreen",
                      },
                    }}
                  />

                  <Button
                    variant="contained"
                    sx={{
                      whiteSpace: "nowrap",
                      backgroundColor: "#34BC56",
                      borderRadius: "22px",
                      textTransform: "capitalize",
                      padding: {
                        md: "10px 60px",
                        xs: "8px 20px",
                      },
                      fontSize: {
                        md: "1rem",
                        xs: "1rem",
                      },
                      "&:hover": {
                        backgroundColor: "#34BC56",
                      },
                    }}
                    onClick={() => {
                      navigate(content.link);
                    }}
                  >
                    {content.buttonText}
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{
                      whiteSpace: "nowrap",
                      border: "1px solid #34BC56",
                      borderRadius: "22px",
                      color: "#34BC56",
                      textTransform: "capitalize",
                      ml: 2,
                      padding: {
                        md: "10px 60px",
                        xs: "8px 20px",
                      },
                      fontSize: {
                        md: "1rem",
                        xs: "1rem",
                      },
                      "&:hover": {
                        border: "1px solid #34BC56",
                      },
                    }}
                    onClick={() => {
                      window.open(content.buttonLink, "_blank");
                    }}
                  >
                    Contact Us
                  </Button>
                  <ChevronRightIcon
                    onClick={handleNext}
                    sx={{
                      color: "black", // Retained or updated color
                      fontSize: "1.5rem",
                      ml: 2,
                      display: {
                        xs: "grid",
                        sm: "grid",
                        md: "none",
                      },
                      transform: "translateY(0%)",
                      borderRadius: "40%",
                      backgroundColor: "#34BC56",
                      "&:hover": {
                        backgroundColor: "lightgreen",
                      },
                    }}
                  />
                </Box>
              </div>
              <div
                className="mt-9 lg:mt-0 h-50 ml-1 lg:col-span-5 lg:flex rounded-sm"
                style={{ alignItems: "center", justifyContent: "center" }}
              >
                <img
                  style={{
                    height: "100%",
                    width: "90%",
                    borderRadius: "12px",
                  }}
                  src={content.image} // Change this image dynamically
                  alt="mockup"
                />
              </div>
            </div>
          </section>
        </Grid>
      </Container>
      <IconButton
        onClick={handlePrevious}
        sx={{
          position: "absolute",
          display: {
            xs: "none",
            sm: "none",
            md: "grid",
          },
          left: "50px",
          top: "50%",
          transform: "translateY(-50%)",
          backgroundColor: "#34BC56",
          color: "black",
          "&:hover": {
            backgroundColor: "#34BC56",
          },
        }}
      >
        <ChevronLeftIcon />
      </IconButton>
      <IconButton
        onClick={handleNext}
        sx={{
          position: "absolute",
          right: "80px",
          top: "50%",
          display: {
            xs: "none",
            sm: "none",
            md: "grid",
          },
          transform: "translateY(-50%)",
          backgroundColor: "#34BC56",
          color: "black",
          "&:hover": {
            backgroundColor: "#34BC56",
          },
        }}
      >
        <ChevronRightIcon />
      </IconButton>
    </Box>
  );
};

export default Section1;
