"use client";
import React from "react";
import { useFormik } from "formik";
import { Box, Button, Grid, Paper, TextField, Typography } from "@mui/material";
import * as yup from "yup";
import {
  updateElectricityBill,
  updateElectricityUnit,
} from "../../../store/reducers/formDetailsSlice";
import { useDispatch } from "react-redux";

const validationSchema = yup.object({
  electricUnit: yup
    .number()
    .typeError("Please enter a valid number")
    .required("Electricity unit consumption is required"),
  billAmount: yup
    .number()
    .typeError("Please enter a valid number")
    .required("Electricity bill is required"),
});

const UnitComsumption = ({ step, prevStep, nextStep }) => {
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      electricUnit: "",
      billAmount: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(updateElectricityUnit(values.electricUnit));
      dispatch(updateElectricityBill(values.billAmount));
      console.log("Form Values:", values);
      nextStep();
    },
  });

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Paper elevation={2}>
          <Box
            sx={{
              width: {
                xs: "90vw",
                md: "700px",
              },
              p: {
                xs: "30px 20px 30px 20px",
                md: "50px 100px 60px 100px",
              },
              bgcolor: "white",
              borderRadius: "22px",
            }}
          >
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontSize: {
                      xs: "18px",
                      md: "23px",
                    },
                    textAlign: "center",
                    fontWeight: 900,
                  }}
                >
                  Average(6 months) electricity unit consumption
                </Typography>
              </Grid>

              <TextField
                sx={{
                  mt: 4,
                  width: {
                    xs: "100%",
                    sm: "350px",
                  },
                }}
                name="electricUnit"
                id="electricUnit"
                placeholder="Electricity unit consumption"
                variant="outlined"
                value={formik.values.electricUnit}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.electricUnit &&
                  Boolean(formik.errors.electricUnit)
                }
                helperText={
                  formik.touched.electricUnit && formik.errors.electricUnit
                }
              />

              <Grid
                item
                xs={12}
                sx={{
                  mt: "30px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: {
                      xs: "18px",
                      md: "23px",
                    },
                    textAlign: "center",
                    fontWeight: 900,
                  }}
                >
                  Average(6 months) electricity bill
                </Typography>
              </Grid>
              <TextField
                sx={{
                  mt: 4,
                  width: {
                    xs: "100%",
                    sm: "350px",
                  },
                }}
                name="billAmount"
                id="billAmount"
                placeholder="Electricity bill"
                variant="outlined"
                value={formik.values.billAmount}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.billAmount && Boolean(formik.errors.billAmount)
                }
                helperText={
                  formik.touched.billAmount && formik.errors.billAmount
                }
              />
              {/* Continue button */}
              <Grid item xs={12}>
                {step < 6 && (
                  <Button
                    sx={{
                      p: {
                        xs: "10px 120px",
                        sm: "10px 100px",
                        md: "12px 130px",
                      },
                      width: "100%",
                      fontSize: "20px",
                      textTransform: "capitalize",
                      color: "white",
                      fontWeight: 900,
                      mt: 5,
                    }}
                    type="submit"
                    onClick={(e) => {
                      e.preventDefault();
                      formik.handleSubmit();
                    }}
                    variant="contained"
                  >
                    Continue
                  </Button>
                )}
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>
    </>
  );
};

export default UnitComsumption;
