import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  Typography,
  IconButton,
  Button,
  TextField,
} from "@mui/material";
import alterhomewebp from "../../assets/alterhomewebp.webp";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Groupwebp from "../../assets/Groupwebp.webp";
// import AdvisorySwiper from "./HomeSwiper";
import featuresimg4 from "../../assets/Welcome - 3 - Static.png";
import featuresimg5 from "../../assets/Image [attachment-full].png";
import featuresimg6 from "../../assets/Image [attachment-full] (1).png";
import cardimg2 from "../../assets/Image [attachment-full] (8).png";
import BusinessImg1 from "../../assets/Business and finance.png";
import BusinessImg2 from "../../assets/Business and finance (1).png";
import BusinessImg3 from "../../assets/Business and finance (2).png";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ContactButton from "../floatingcontactus/contactbutton";
import resco from "../../assets/resco.png";
import res1 from "../../assets/res1.png";
import res2 from "../../assets/res2.png";
import res3 from "../../assets/res3.png";
import res4 from "../../assets/res4.png";
import res5 from "../../assets/res5.png";
import althome1 from "../../assets/althome1webp.webp";
import althome2 from "../../assets/althome2webp.webp";
import althome3 from "../../assets/althome3webp.webp";
import althome4 from "../../assets/althome4webp.webp";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

const validationSchema = Yup.object({
  pincode: Yup.string()
    .matches(/^\d{6}$/, "Pincode must be a 6-digit number")
    .required("Pincode is required"),
});

const boxesData = [
  {
    image: res1,
    title: "Immediate Savings",
    description:
      "With either model, the investor can expect immediate savings on their energy bills. The exact amount of savings will depend on factors such as the size of the solar system, local energy rates, and sunlight availability.",
  },
  {
    image: res2,
    title: "Return on Investment (ROI)",
    description:
      "In the CAPEX model, the investor will see a return on their investment over time through energy savings. This ROI could be significant, especially considering the long lifespan of solar panels and the rising costs of traditional energy sources.",
  },
  {
    image: res3,
    title: "Predictable Cash Flows",
    description:
      "With the RESCO model, the investor benefits from predictable cash flows generated by the PPA. This can provide a steady income stream over the duration of the agreement, offering a reliable return on investment.",
  },
  {
    image: res4,
    title: "Risk Mitigation",
    description:
      "By combining both models, the investor can mitigate some of the risks associated with solar investment. For example, they can spread the upfront costs across multiple financing options while still enjoying the long-term benefits of ownership and steady cash flows from the PPA.",
  },
  {
    image: res5,
    title: "Environmental Benefits",
    description:
      " Investing in solar energy not only offers financial benefits but also contributes to environmental sustainability by reducing greenhouse gas emissions and dependence on fossil fuels.",
  },
];

const boxesData2 = [
  {
    image: althome1,
    title: "Solar Rooftop Installation",
    description:
      "We enable housing societies to generate clean, renewable energy through solar rooftop installations that will significantly lower their carbon footprint and energy bills.",
  },
  {
    image: althome2,
    title: "Waste Composters",
    description:
      "We provide advanced waste composting solutions to help societies efficiently manage organic waste and contribute to a healthier environment.",
  },
  {
    image: althome3,
    title: "Waste to CBG",
    description:
      "We facilitate the conversion of organic waste into Compressed Biogas (CBG). This not only reduces greenhouse gas emissions but also offers societies a renewable energy source for cooking and heating needs.",
  },
  {
    image: althome4,
    title: "Smart energy saving solutions",
    description:
      "By combining both models, the investor can mitigate some of the risks associated with solar investment. For example, they can spread the upfront costs across multiple financing options while still enjoying the long-term benefits of ownership and steady cash flows from the PPA.",
  },
];

const ListBox = {
  width: { xs: "100%", sm: "100%", lg: "100%" },
  minHeight: { sm: "170px", md: "100px" },
  //   display: {
  //     xs: "grid",
  //     md: "flex",
  //   },
  alignItems: "center",
  borderRadius: "10px",
  // boxShadow: " 0 3px 6px rgba(0,0,0,.161)",
  margin: {
    // sx:"0",
    md: "0 2% 12px",
  },
  mt: {
    sm: 0,
    xs: 1,
  },
  //   margin: "0 2% 42px",
  // padding: "18px 15px 14px",
};

const ListItemTextStyling = {
  fontSize: "17px",
  fontFamily: "sans-serif",
  color: "#404040",
  // paddingLeft: "10px",
  paddingLeft: "15px",
  lineHeight: { xs: "1.5" },
  // border:"1px solid red"
};

const AlterHome = () => {
  const openForm = () => {
    window.open("https://forms.gle/ETe4b1KdbsqAAscu8", "_blank"); // Opens in a new tab
  };

  const [currentIndex, setCurrentIndex] = useState(0);

  // for time out
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % boxesData.length);
    }, 5000); // Change content every 5 seconds

    return () => clearInterval(interval);
  }, [boxesData.length]);

  // for time out of serv part
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentServIndex((prevIndex) => (prevIndex + 1) % boxesData2.length);
    }, 5000); // Change content every 5 seconds

    return () => clearInterval(interval);
  }, [boxesData2.length]);
  // for time out

  const handlePrevious = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + boxesData.length) % boxesData.length
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % boxesData.length);
  };

  const currentBox = boxesData[currentIndex];
  // For services box
  const [currentServIndex, setCurrentServIndex] = useState(0);

  const handlePreviousServ = () => {
    setCurrentServIndex(
      (prevIndex) => (prevIndex - 1 + boxesData2.length) % boxesData2.length
    );
  };

  const handleNextServ = () => {
    setCurrentServIndex((prevIndex) => (prevIndex + 1) % boxesData2.length);
  };

  const currentBoxServ = boxesData2[currentServIndex];

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      pincode: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      sessionStorage.setItem("pinCode", values.pincode);
      navigate("/form");
    },
  });
  return (
    <Box style={{ overflowX: "hidden" }}>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            backgroundImage: `url(${alterhomewebp})`, // Apply background image
            backgroundSize: "cover", // Make sure the image covers the entire area
            backgroundPosition: "center", // Center the image
            backgroundRepeat: "no-repeat", // Prevent the image from repeating
          }}
        >
          <Grid
            container
            sx={{
              display: "flex",
              width: "100%",
            }}
          >
            <Grid
              item
              xs={12}
              md={8}
              sx={{
                flex: 1,
                p: {
                  xs: "100px 40px",
                  md: "200px 80px",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: {
                    md: "1.6rem",
                    xs: "2rem",
                  },
                  color: "#34BC56",
                  fontWeight: 900,
                }}
              >
                AlterHome
              </Typography>
              <Typography
                sx={{
                  fontSize: {
                    md: "4rem",
                    xs: "3rem",
                  },
                  color: "#EBFFF5",
                  fontFamily: "serif",
                  fontWeight: 900,
                  lineHeight: {
                    md: "100px",
                    xs: "50px",
                  },
                }}
              >
                Empowering Housing <br /> Societies towards <br />
                sustainability
              </Typography>
              <Button
                variant="contained"
                color="primary"
                endIcon={<ArrowForwardIcon />}
                sx={{
                  mt: 2, // margin top
                  fontSize: {
                    md: "1.2rem",
                    xs: ".8rem",
                  }, // increased font size
                  padding: "15px 25px", // added padding
                  fontWeight: "bold",
                  backgroundColor: "#34BC56",
                  borderRadius: "40px", // rounded corners
                  ":hover": {
                    backgroundColor: "#2DA047",
                  },
                }}
                onClick={() => {
                  openForm();
                }}
              >
                Reach out to us
              </Button>
            </Grid>

            <Grid
              item
              xs={12}
              md={4}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: {
                  xs: "center",
                  md: "flex-start",
                },
                p: 3,
              }}
            >
              <Box
                sx={{
                  backgroundColor: "rgba(255, 255, 255, 0.9)",
                  borderRadius: "8px",
                  width: {
                    xs: "100%",
                    md: "320px",
                  },
                  height: "200px",
                  m: {
                    xs: "-80px 0px 0px 0",
                    md: "0 40px 200px 0",
                  },
                  zIndex: 1, // Keep the form on top of the image
                  position: "relative", // Make sure it stays relative to the container
                }}
              >
                <form onSubmit={formik.handleSubmit}>
                  <Grid container>
                    <Grid
                      item
                      sx={{
                        width: "100%",
                        padding: "30px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#666666",
                        }}
                      >
                        Enter Pincode
                      </Typography>
                      <TextField
                        sx={{
                          backgroundColor: "white",
                        }}
                        placeholder="Type here..."
                        fullWidth
                        id="pincode"
                        name="pincode"
                        value={formik.values.pincode}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.pincode &&
                          Boolean(formik.errors.pincode)
                        }
                        helperText={
                          formik.touched.pincode && formik.errors.pincode
                        }
                      />
                      <br />
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: "#34BC56",
                          p: "10px 40px",
                          mt: "10px",
                          width: "100%",
                        }}
                        type="submit"
                      >
                        Next
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Grid>
          </Grid>

          {/* Background Image Section */}
          <Box style={{ position: "relative" }}>
            {/* <Box
              sx={{
                width: "100%",
                height: {
                  md: "100vh",
                  xs: "90vh",
                },
                overflow: "hidden",
                backgroundColor: "black",
              }}
            >
              <img
                src={alterhomewebp}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  opacity: 0.8,
                }}
              />
            </Box> */}
            <Box
              style={{
                position: "absolute",
                top: "35%",
                left: "5%",
                color: "white",
                fontWeight: "bold",
              }}
            ></Box>
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{
            height: {
              xs: "60vw",
              md: "8vw",
            },
            width: "100vw",
            fontSize: { xs: "1.2rem", sm: "1.2rem" },
            fontWeight: 500,
            padding: {
              xs: 2,
              md: 10,
            },
            paddingTop: {
              xs: "30px",
              md: 10,
            },
            pb: {
              xs: "30px",
              md: 10,
            },
            backgroundColor: "#EBFFF5",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            whiteSpace: "pre-wrap",
            wordWrap: "break-word",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontSize: {
                xs: "4vw",
                sm: "3vw",
                md: "2vw",
                lg: "1.5vw",
              },
              textAlign: "center", // Center-align the text
            }}
          >
            {/* Place your description here */}
            At AlterHome, we believe in revolutionizing housing societies by
            guiding them towards a sustainable, net-zero future. <br />
            Through our comprehensive services and solutions, we empower
            communities to embrace renewable energy, efficient waste management,
            and eco-friendly practices and that too with our cutting-edge
            financing to make it capital friendly.
          </Typography>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          p: {
            xl: "95px",
            md: "70px",
            xs: "20px",
          },
          //   height: "100vh",
        }}
      >
        <Box>
          <Typography
            variant="h3"
            sx={{
              fontSize: { xs: "1.2rem", sm: "1.2rem" },
              fontWeight: "bold",
              width: "100%",
              color: "#3CB769",
              textTransform: "uppercase",
            }}
          >
            our services
          </Typography>
          <Typography
            variant="h3"
            sx={{
              mt: 1,
              fontSize: { xs: "1.2rem", sm: "1.7rem" },
              fontFamily: "unset",
              width: "100%",
              color: "black",
              fontWeight: 600,
            }}
          >
            Guiding communities towards a sustainable future with financing for
            renewable energy, waste management & eco-friendly practices.
          </Typography>
        </Box>
      </Grid>
      <Box
        sx={{
          backgroundColor: "#EBFFF5",
          width: "100%",
          p: { xs: 2, sm: 3 },
          borderRadius: "10px",
          mt: {
            md: "0px",
            xs: "0",
          },
        }}
      >
        <Box sx={{ px: { xs: 0, sm: 0 } }}>
          {/* <AdvisorySwiper /> */}

          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ minHeight: "30vh" }}
          >
            <Grid item md={10} xs={12}>
              <Box
                sx={{ maxHeight: "90vh", overflow: "hidden", margin: "auto" }}
              >
                <Grid
                  container
                  sx={{
                    ml: {
                      // md: "80px",
                      xs: "0px",
                    },
                    background: "rgba(255, 255, 255, 0.501)",
                    p: "20px",
                    mb: "20px",
                  }}
                >
                  <Grid
                    item
                    md={1}
                    xs={12}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <IconButton
                      onClick={handlePreviousServ}
                      sx={{
                        backgroundColor: "#34BC56",
                        color: "black",
                        display: {
                          xs: "none",
                          sm: "none",
                          md: "grid",
                        },
                      }}
                    >
                      <ChevronLeftIcon />
                    </IconButton>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    xs={12}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      paddingRight: "20px",
                    }}
                  >
                    <img
                      src={currentBoxServ.image}
                      alt={currentBoxServ.title}
                      style={{
                        width: "100%",
                        height: "85%",
                        borderRadius: "10px",
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    md={7}
                    xs={12}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      paddingRight: "5px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#34BC56",
                        fontSize: "1.6rem",
                        textTransform: "uppercase",
                        fontWeight: 900,
                        mt: "5px",
                      }}
                    >
                      {currentBoxServ.title}
                    </Typography>
                    <Typography
                      sx={{
                        mt: "15px",
                        fontWeight: 500,
                        fontSize: "1rem",
                        fontFamily: "sans-serif",
                        color: "#404040",
                      }}
                    >
                      {currentBoxServ.description}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={1}
                    xs={12}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      paddingLeft: "0px",
                    }}
                  >
                    <IconButton
                      onClick={handlePrevious}
                      sx={{
                        backgroundColor: "#34BC56",
                        color: "black",
                        display: {
                          xs: "grid",
                          sm: "grid",
                          md: "none",
                        },
                      }}
                    >
                      <ChevronLeftIcon />
                    </IconButton>
                    <IconButton
                      onClick={handleNextServ}
                      sx={{ backgroundColor: "#34BC56", color: "black" }}
                    >
                      <ChevronRightIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box
        sx={{
          mt: {
            md: "100px",
            xs: "80px",
          },
          pb: {
            lg: "0px",
            md: "50px",
            xs: "0",
          },
          height: {
            md: "100%",
            xs: "100%",
          },
          width: "100%",
          backgroundColor: "#EBFFF5",
        }}
      >
        {/* <img width={"100%"} src={Group} /> */}
        <Grid container>
          <Grid item md={7} xs={12}>
            <Grid
              md={12}
              xs={12}
              sx={{
                padding: {
                  md: "80px",
                  xs: "20px",
                },
              }}
            >
              <Typography
                sx={{
                  textTransform: "uppercase",
                  color: "#34BC56",
                  fontSize: {
                    md: "1.2rem",
                    xs: "1.2rem",
                  },
                  fontWeight: 900,
                  fontFamily: "sans-serif",
                }}
              >
                Our Operating Model
              </Typography>
              <Typography
                sx={{
                  textTransform: "uppercase",
                  fontSize: {
                    md: "2rem",
                    xs: "1.6rem",
                  },
                  fontWeight: 900,
                  fontFamily: "sans-serif",
                }}
              >
                Resco + Capex
              </Typography>
              <Typography
                sx={{
                  mt: 2,
                  fontSize: {
                    md: "1.4rem",
                    xs: "1rem",
                  },
                  fontWeight: {
                    md: 100,
                  },
                  fontFamily: "sans-serif",
                  color: "#404040",
                }}
              >
                A combination of RESCO and CapEx models makes possible the
                ownership of assets without upfront capex investment. <br />
                <List
                  sx={{
                    listStyleType: "disc",
                    pl: 3,
                    "& .MuiListItem-root": {
                      display: "list-item",
                    },
                  }}
                >
                  <ListItem>100% asset investment</ListItem>
                  <ListItem>A better long term ROI</ListItem>
                  <ListItem>
                    EMI cost is offset by savings in power bill or fees incurred
                    on waste disposal{" "}
                  </ListItem>
                  <ListItem>Benefits of depreciation</ListItem>
                  <ListItem>Government subsidy</ListItem>
                </List>
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            md={5}
            xs={12}
            sx={{
              padding: {
                md: "0 50px 0px 20px", // 0 for top padding since it will be handled separately
                xs: "10px 10px",
              },
              paddingTop: {
                md: "150px",
              },
            }}
          >
            <img style={{ height: "70%", width: "100%" }} src={resco} />
          </Grid>
        </Grid>

        <Box
          sx={{
            backgroundColor: "#EBFFF5",
            width: "100%",
            p: { xs: 2, sm: 4 },
            borderRadius: "10px",
            mt: {
              md: "0px",
              xs: "0",
            },
          }}
        >
          <Box sx={{ px: { xs: 0, sm: 0 } }}>
            {/* <AdvisorySwiper /> */}

            <Grid
              container
              justifyContent="center"
              alignItems="center"
              sx={{ minHeight: "30vh" }}
            >
              <Grid item md={10} xs={12}>
                <Box
                  sx={{ maxHeight: "90vh", overflow: "hidden", margin: "auto" }}
                >
                  <Grid
                    container
                    sx={{
                      ml: {
                        // md: "80px",
                        xs: "0px",
                      },
                      background: "rgba(255, 255, 255, 0.501)",
                      p: "20px",
                      mb: "20px",
                    }}
                  >
                    <Grid
                      item
                      md={1}
                      xs={12}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <IconButton
                        onClick={handlePrevious}
                        sx={{
                          backgroundColor: "#34BC56",
                          color: "black",
                          display: {
                            xs: "none",
                            sm: "none",
                            md: "grid",
                          },
                        }}
                      >
                        <ChevronLeftIcon />
                      </IconButton>
                    </Grid>
                    <Grid
                      item
                      md={3}
                      xs={12}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingRight: "20px",
                      }}
                    >
                      <img
                        src={currentBox.image}
                        alt={currentBox.title}
                        style={{
                          width: "90%",
                          height: "80%",
                          borderRadius: "10px",
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      md={7}
                      xs={12}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        paddingRight: "5px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#34BC56",
                          fontSize: "1.6rem",
                          textTransform: "uppercase",
                          fontWeight: 900,
                          mt: "5px",
                        }}
                      >
                        {currentBox.title}
                      </Typography>
                      <Typography
                        sx={{
                          mt: "15px",
                          fontWeight: 500,
                          fontSize: "1rem",
                          fontFamily: "sans-serif",
                          color: "#404040",
                        }}
                      >
                        {currentBox.description}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      md={1}
                      xs={12}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        paddingLeft: "0px",
                      }}
                    >
                      <IconButton
                        onClick={handlePrevious}
                        sx={{
                          backgroundColor: "#34BC56",
                          color: "black",
                          display: {
                            xs: "grid",
                            sm: "grid",
                            md: "none",
                          },
                        }}
                      >
                        <ChevronLeftIcon />
                      </IconButton>
                      <IconButton
                        onClick={handleNext}
                        sx={{ backgroundColor: "#34BC56", color: "black" }}
                      >
                        <ChevronRightIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          height: {
            md: "50%",
            xs: "100%",
          },
          width: "100%",
          backgroundColor: "#D2E2DA",
        }}
      >
        <Grid
          container
          sx={{
            height: "100%",
            width: "100%",
          }}
        >
          <Grid
            item
            md={4}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
            }}
          >
            <Box
              sx={{
                height: "100%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                padding: {
                  md: "60px",
                  xs: "40px",
                },
                // backgroundColor:"red"
              }}
            >
              <img
                src={BusinessImg1}
                style={{
                  height: "100px",
                  width: "100px",
                }}
                alt="easy online application modes"
              />
              <Typography
                sx={{
                  fontSize: "1.5rem",
                  fontFamily: "ar",
                  fontWeight: 900,
                  mt: 2,
                  whiteSpace: "nowrap",
                }}
              >
                Comprehensive Financing*
              </Typography>
              <Typography
                sx={{
                  fontSize: "1rem",
                  fontFamily: "sans-serif",
                  fontWeight: 200,
                  whiteSpace: "wrap",
                  textAlign: "center", // Add this line to center the text
                }}
              >
                <Box
                  sx={{
                    display: "inline-block",
                    textAlign: "center",
                    "@media (max-width: 768px)": {
                      display: "block",
                      textAlign: "center",
                    },
                  }}
                >
                  Get customized financing for your housing society projects
                  with easy EMIs, eliminating upfront expenses.
                </Box>
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            md={4}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
            }}
          >
            <Box
              sx={{
                height: "100%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                padding: {
                  md: "60px",
                  xs: "40px",
                },
                // backgroundColor:"red"
              }}
            >
              <img
                src={BusinessImg2}
                style={{
                  height: "100px",
                  width: "100px",
                }}
                alt="easy online application modes"
              />
              <Typography
                sx={{
                  fontSize: "1.5rem",
                  fontFamily: "ar",
                  fontWeight: 900,
                  mt: 2,
                }}
              >
                Customized Approach
              </Typography>
              <Typography
                sx={{
                  fontSize: "1rem",
                  fontFamily: "sans-serif",
                  fontWeight: 200,
                  whiteSpace: "wrap",
                  textAlign: "center", // Add this line to center the text
                }}
              >
                <Box
                  sx={{
                    display: "inline-block",
                    textAlign: "center",
                    "@media (max-width: 768px)": {
                      display: "block",
                      textAlign: "center",
                    },
                  }}
                >
                  Our team develops project blueprints for each housing society,
                  considering their unique energy requirements and goals, to
                  deliver tailored solutions that meet their needs effectively.
                </Box>
              </Typography>
            </Box>
          </Grid>{" "}
          <Grid
            item
            md={4}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
            }}
          >
            <Box
              sx={{
                height: "100%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                padding: {
                  md: "60px",
                  xs: "40px",
                },
                // backgroundColor:"red"
              }}
            >
              <img
                src={BusinessImg3}
                style={{
                  height: "100px",
                  width: "100px",
                }}
                alt="easy online application modes"
              />
              <Typography
                sx={{
                  fontSize: "1.5rem",
                  fontFamily: "ar",
                  fontWeight: 900,
                  mt: 2,
                }}
              >
                Ecosystem Access
              </Typography>
              <Typography
                sx={{
                  fontSize: "1rem",
                  fontFamily: "sans-serif",
                  fontWeight: 200,
                  whiteSpace: "wrap",
                  textAlign: "center", // Add this line to center the text
                }}
              >
                <Box
                  sx={{
                    display: "inline-block",
                    textAlign: "center",
                    "@media (max-width: 768px)": {
                      display: "block",
                      textAlign: "center",
                    },
                  }}
                >
                  Through our extensive network and partnerships, housing
                  societies gain access to the AlterHome ecosystem, comprising
                  resources, expertise and opportunities.
                </Box>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          position: {
            md: "relative",
            xs: "relative",
          },
          height: {
            md: "80vh",
            xs: "100% ",
          },
          mt: {
            md: 0,
            // xs: 2,
          },
          overflow: "hidden",
          backgroundColor: "#ebfff0",
        }}
      >
        <Box
          sx={{
            display: {
              md: "initial",
              xs: "none",
            },
          }}
        >
          <img
            src={Groupwebp}
            style={{
              height: "100%",
              width: "100%",
            }}
          />
        </Box>
        <Container
          maxWidth={"xl"}
          sx={{
            position: {
              md: "absolute",
              xs: "",
            },
            top: {
              md: "50%",
              xs: "0",
            },
            left: "50%",
            transform: {
              md: "translate(-50%, -50%)",
              xs: "",
            },
            padding: {
              md: "0px 0px",
              xs: "20px 0px",
            },
            flexDirection: {
              xs: "reverse",
            },
            // mt: {
            //   xl: "-150px",
            //   md: "-90px",
            // },
          }}
        >
          <Grid
            container
            sx={{
              display: "flex",
              alignItems: { xs: "center" },
              justifyContent: { xs: "center" },
              flexDirection: {
                md: "row",
                xs: "column-reverse",
              },
            }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              sx={{
                padding: { xs: "5px", sm: "48px", md: "48px" },
                display: "flex",
                alignItems: { xs: "center" },
                justifyContent: { xs: "center" },
                flexDirection: {
                  md: "column",
                  xs: "reverse",
                },
              }}
            >
              <Box>
                <List>
                  <ListItem
                    sx={{
                      ...ListBox,
                    }}
                  >
                    <img
                      src={featuresimg4}
                      alt="easy online application modes"
                    />
                    <Typography
                      display="inline"
                      sx={{ ...ListItemTextStyling }}
                    >
                      <strong>Save Costs:</strong> Our solution significantly
                      reduces energy expenses, allowing society to allocate
                      resources towards other community initiatives.
                    </Typography>
                  </ListItem>
                  <ListItem sx={{ ...ListBox }}>
                    <img
                      src={featuresimg5}
                      alt="easy online application modes"
                    />
                    <Typography
                      display="inline"
                      sx={{ ...ListItemTextStyling }}
                    >
                      <strong> Green Impact:</strong> By adopting renewable
                      energy and efficient waste management practices, housing
                      societies contribute to a cleaner, greener planet,
                      preserving natural resources for future generations.
                    </Typography>
                  </ListItem>
                  <ListItem sx={{ ...ListBox }}>
                    <img
                      src={featuresimg6}
                      alt="easy online application modes"
                    />
                    <Typography
                      display="inline"
                      sx={{ ...ListItemTextStyling }}
                    >
                      <strong> Engage Community:</strong> Through our tailored
                      solutions, we foster community engagement and
                      collaboration, empowering residents to actively
                      participate in sustainable initiatives and make a positive
                      impact.
                    </Typography>
                  </ListItem>
                </List>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              sx={{
                padding: { xs: "20px", sm: "0" },
                display: "flex",
                alignItems: { xs: "center", xl: "flex-start" },
                justifyContent: { xs: "center", xl: "flex-start" },
              }}
            >
              <Box>
                <img
                  alt="loading"
                  src={cardimg2}
                  style={{
                    width: "100%",
                    borderRadius: "8px",
                    transition: "transform 0.3s, box-shadow 0.3s", // Changed: Added box-shadow to transition
                    boxShadow: "none", // Changed: Initially, no shadow
                  }}
                  onMouseOver={(e) => {
                    e.target.style.transform = "scale(1.1)";
                    e.target.style.boxShadow = "0px 0px 0px rgba(0, 0, 0, 1)"; // Changed: Adding shadow on hover
                  }}
                  onMouseOut={(e) => {
                    e.target.style.transform = "scale(1)";
                    e.target.style.boxShadow = "none"; // Changed: Removing shadow on hover out
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <ContactButton redirectUrl="https://forms.gle/ETe4b1KdbsqAAscu8" />
    </Box>
  );
};

export default AlterHome;
