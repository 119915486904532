import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pincode: "",
  f_name: "",
  l_name: "",
  email: "",
  mobile: "",
  premise_type: "",
  address: "",
  electricity_unit: "",
  electricity_bill: "",
  date_for_visit: "",
};

const formDetailsSlice = createSlice({
  name: "formDetails",
  initialState,
  reducers: {
    updatePincode: (state, action) => {
      state.pincode = action.payload;
    },
    updateFirstName: (state, action) => {
      state.f_name = action.payload;
    },
    updateLastName: (state, action) => {
      state.l_name = action.payload;
    },
    updateEmail: (state, action) => {
      state.email = action.payload;
    },
    updateMobile: (state, action) => {
      state.mobile = action.payload;
    },
    updatePremiseType: (state, action) => {
      state.premise_type = action.payload;
    },
    updateAddress: (state, action) => {
      state.address = action.payload;
    },
    updateElectricityUnit: (state, action) => {
      state.electricity_unit = action.payload;
    },
    updateElectricityBill: (state, action) => {
      state.electricity_bill = action.payload;
    },
    updateDateForVisit: (state, action) => {
      state.date_for_visit = action.payload;
    },
    resetForm: () => initialState,
  },
});

export const {
  updatePincode,
  updateFirstName,
  updateLastName,
  updateEmail,
  updateMobile,
  updatePremiseType,
  updateAddress,
  updateElectricityUnit,
  updateElectricityBill,
  updateDateForVisit,
  resetForm,
} = formDetailsSlice.actions;

export default formDetailsSlice.reducer;
