"use client";
import React from "react";
import { useFormik } from "formik";
import { Box, Button, Grid, Paper, TextField, Typography } from "@mui/material";
import * as yup from "yup";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { updateDateForVisit } from "../../../store/reducers/formDetailsSlice";

const validationSchema = yup.object({
  visitDate: yup.date().required("Site visit date is required").nullable(),
});

const SiteDetails = ({ step, prevStep, nextStep }) => {
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      visitDate: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const visitDates = new Date(values?.visitDate).toISOString().split("T")[0];
      console.log(visitDates);
      dispatch(updateDateForVisit(visitDates));
      nextStep();
   
    },
  });

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Paper elevation={2}>
          <Box
            sx={{
              width: {
                xs: "90vw",
                md: "700px",
              },
              p: {
                xs: "30px 20px 30px 20px",
                md: "50px 100px 60px 100px",
              },
              bgcolor: "white",
              borderRadius: "22px",
            }}
          >
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontSize: {
                      xs: "18px",
                      md: "23px",
                    },
                    textAlign: "center",
                    fontWeight: 900,
                  }}
                >
                  Enter a date for site visit.
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                mt={4}
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TextField
                  fullWidth
                  sx={{
                    width: {
                      xs: "100%",
                      sm: "350px",
                    },
                  }}
                  id="visitDate"
                  name="visitDate"
                  label="Site Visit Date"
                  type="date"
                  value={formik.values.visitDate}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.visitDate && Boolean(formik.errors.visitDate)
                  }
                  helperText={
                    formik.touched.visitDate && formik.errors.visitDate
                  }
                  InputLabelProps={{
                    shrink: true, // Ensures label stays visible when using the date picker
                  }}
                />
              </Grid>

              {/* Continue Button */}
              <Grid item xs={12}>
                {step < 6 && (
                  <Button
                    sx={{
                      p: {
                        xs: "10px 120px",
                        sm: "10px 100px",
                        md: "12px 130px",
                      },
                      width: "100%",
                      fontSize: "20px",
                      textTransform: "capitalize",
                      color: "white",
                      fontWeight: 900,
                      mt: 5,
                    }}
                    type="submit"
                    onClick={(e) => {
                      e.preventDefault();
                      formik.handleSubmit();
                    }}
                    variant="contained"
                  >
                    Continue
                  </Button>
                )}
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>
    </>
  );
};

export default SiteDetails;
