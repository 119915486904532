import {
  Box,
  Button,
  Container,
  Grid,
  List,
  ListItem,
  Typography,
  TextField,
} from "@mui/material";

import React, { useState } from "react";
import logo from "../../assets/Logo.svg";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Link, useNavigate } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const listItemStyling = {
  color: "#ffffff",
  fontSize: "14px",
  fontFamily: "Inter,sans-serif",
  fontWeight: "700",
  paddingLeft: "0",
};
const styles = {
  mt: "10px",
  fontWeight: 300,
  letterSpacing: "0px",
  color: "black",
  paddingLeft: "0px",
  bordeColor: "#141414",
  borderRadius: 0,
  paddingLeft: "18px",
  backgroundColor: "#CBCDCC",
};
const Footer = () => {
  const navigate = useNavigate();
  const handleLogoClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    // navigate("/");
  };
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);

  const handleChange = (event) => {
    setEmail(event.target.value);
    setError(!event.target.validity.valid);
  };
  const openForm = () => {
    window.open("https://forms.gle/zYY9kWjyCjJb7Lr3A", "_blank"); // Opens in a new tab
  };

  //---------
  //--------

  return (
    <Box>
      <Box
        sx={{
          backgroundColor: "#141414",
          padding: {
            md: "45px 15px 45px 0px",
            xs: "45px 15px 45px 10px",
          },
        }}
      >
        <Container maxWidth={"xl"}>
          <Grid
            container
            spacing={2}
            sx={{
              padding: { md: "0 7%" },
              display: "flex",
              flexDirection: { xs: "column", sm: "row", flexWrap: "wrap" },
            }}
          >
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <Box>
                <Box sx={{ marginBottom: "28px" }}>
                  <Link
                    to="/"
                    onClick={() => {
                      handleLogoClick();
                    }}
                  >
                    <img
                      style={{ height: "100%", width: "50%" }}
                      src={logo}
                      alt="Credmudra"
                    />
                  </Link>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: "#f7d64a",
                    fontSize: "25px",
                    fontFamily: "sans-serif",
                    fontWeight: "900",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#ffffff",
                      fontSize: "12px",
                      fontFamily: "sans-serif",
                      fontWeight: "700",
                      padding: "20px 30px 20px 0",
                    }}
                  >
                    7th Floor, Konark Alpha, <br /> Kharadi-Bypass, Kharadi,
                    Pune 411014, Maharashtra, India
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    color: "#ffffff",
                    fontSize: "14px",
                    fontFamily: "sans-serif",
                    fontWeight: "700",
                    marginBottom: "30px",
                  }}
                >
                  <a href="mailto:hello@altercarbon.in">hello@altercarbon.in</a>
                </Typography>

                <Typography
                  sx={{
                    color: "#ffffff",
                    fontSize: "14px",
                    fontFamily: "Inter",
                    fontWeight: "700",
                    marginBottom: "20px",
                  }}
                >
                  <a href="tel:+9102048555520">91-020-4855 5520</a>
                </Typography>
                <Box>
                  <Typography
                    sx={{
                      color: "#ffffff",
                      fontSize: "18px",
                      fontFamily: "Inter",
                      fontWeight: "600",
                      marginBottom: "5px",
                    }}
                  >
                    Follow Us On:
                  </Typography>

                  <Box sx={{ display: "flex", gap: "10px", color: "#ffffff" }}>
                    {/* <FacebookOutlinedIcon /> */}
                    <a
                      href="https://www.linkedin.com/company/altercarbon"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <LinkedInIcon />
                    </a>
                    {/* <TwitterIcon /> */}
                    <a
                      href="https://www.instagram.com/altercarbon.in/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <InstagramIcon />
                    </a>

                    {/* <YouTubeIcon /> */}
                  </Box>
                </Box>
                <Typography
                  sx={{
                    color: "#ffffff",
                    fontSize: "12px",
                    fontFamily: "sans-serif",
                    fontWeight: "400", // Set default font weight to 400
                    padding: "30px 30px 20px 0",
                  }}
                >
                  <span style={{ fontWeight: 700 }}> *Please note</span> -
                  Financial solutions are through partnering financial
                  institutions
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={2}>
              <Box>
                <Typography
                  sx={{
                    color: "#ffffff",
                    fontSize: "18px",
                    fontFamily: "Inter",
                    fontWeight: "700",
                    marginBottom: "10px",
                  }}
                >
                  Our Products
                </Typography>
                <List sx={{ paddingTop: "16px" }}>
                  <ListItem sx={{ ...listItemStyling }}>
                    <Link
                      to="/alter-home"
                      onClick={() => {
                        handleLogoClick();
                      }}
                    >
                      AlterHome
                    </Link>
                  </ListItem>
                  <ListItem sx={{ ...listItemStyling }}>
                    <Link
                      to="/alter-advisory"
                      onClick={() => {
                        handleLogoClick();
                      }}
                    >
                      AlterAdvisory
                    </Link>
                  </ListItem>
                  <ListItem sx={{ ...listItemStyling }}>
                    <Link
                      to="/alter-credit"
                      onClick={() => {
                        handleLogoClick();
                      }}
                    >
                      AlterCredit
                    </Link>
                  </ListItem>
                </List>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={3}>
              <Box>
                <Typography
                  sx={{
                    color: "#ffffff",
                    fontSize: "18px",
                    fontFamily: "Inter",
                    fontWeight: "700",
                    marginBottom: "10px",
                  }}
                >
                  Our Company
                </Typography>
                <List sx={{ paddingTop: "16px" }}>
                  <ListItem sx={{ ...listItemStyling }}>
                    <Link
                      to="/about-us"
                      onClick={() => {
                        handleLogoClick();
                      }}
                    >
                      About Us
                    </Link>
                  </ListItem>
                  <ListItem sx={{ ...listItemStyling }}>
                    <Link
                      to="/contact-us"
                      onClick={() => {
                        handleLogoClick();
                      }}
                    >
                      Contact Us
                    </Link>
                  </ListItem>
                  <ListItem sx={{ ...listItemStyling }}>
                    <Link
                      to="/privacy-policy"
                      onClick={() => {
                        handleLogoClick();
                      }}
                    >
                      Privacy Policy
                    </Link>
                  </ListItem>
                  <ListItem sx={{ ...listItemStyling }}>
                    <Link
                      to="/terms-and-conditions"
                      onClick={() => {
                        handleLogoClick();
                      }}
                    >
                      {" "}
                      Terms and Condition
                    </Link>
                  </ListItem>
                </List>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%", // Ensures the Box takes the full height of the Grid item
                }}
              >
                {/* <Button
                  variant="contained"
                  endIcon={<ArrowForwardIcon />}
                  sx={{
                    fontSize: "1rem", // increased font size
                    padding: "15px 30px", // added padding
                    fontWeight: "bold",
                    backgroundColor: "#34BC56",
                    borderRadius: "80px", // rounded corners
                    fontFamily: "sans-serif", // font family
                    ":hover": {
                      backgroundColor: "#2DA047",
                    },
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "center",
                  }}
                  onClick={() => {
                    openForm();
                  }}
                >
                  Get in touch
                </Button>

                <Typography
  sx={{
    color: "#ffffff",
    fontSize: "18px",
    fontFamily: "Inter,sans-serif",
    fontWeight: "700",
    padding: "0 20px 10px 0",
  }}
>
  Get In Touch
</Typography> */}

                <Box
                  sx={{
                    maxWidth: "680px",
                    margin: "0px 0",
                    textAlign: "center",
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <Button
                      href="https://forms.gle/zYY9kWjyCjJb7Lr3A"
                      target="_blank"
                      type="submit"
                      className="btn"
                      sx={{
                        height: "45px",
                        borderRadius: "32px",
                        color: "white",
                        p: {
                          xs: "30px 30px",
                          md: "30px 40px",
                        },
                        mt: {
                          md: 2,
                          xs: 2,
                        },
                        fontWeight: 800,
                        background: "#34BC56 0% 0% no-repeat padding-box",
                        fontSize: { xs: "18px", sm: "18px" },
                        textTransform: "uppercase",
                        transition: "0.4s all ease-in-out",
                        marginLeft: "1px",
                        "&:hover": {
                          background: "#34BC56",
                          color: "white",
                        },
                      }}
                    >
                      Get IN TOUCH
                    </Button>
                  </Box>
                </Box>

                <Box
                  sx={{
                    color: "#ffffff",
                    fontSize: "18px",
                    fontFamily: "Inter,sans-serif",
                    fontWeight: "300",
                    padding: "20px 20px 20px 0",
                  }}
                >
                  {/* <Typography sx={{ color: "#ffffff" }}>
    * Will send you weekly updates for your better finance management.
  </Typography> */}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box sx={{ borderTop: "1px solid #f7d64a" }}>
        <Container maxWidth={"xl"}>
          <Box textAlign="center" sx={{ width: "100%", margin: "0 0 auto" }}>
            <Typography
              sx={{
                padding: "10px",
                color: "#404040",
                fontSize: "16px",
                fontFamily: "Inter,sans-serif",
                fontWeight: "300",
              }}
            >
              Copyright @ AlterCarbon 2024. All Rights Reserved.
            </Typography>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default Footer;
