import React from "react";
import Section1 from "./section1";
import Section2 from "./section2";
import Section3 from "./section3";
import Section4 from "./section4";
import Section5 from "./section5";
import ContactButton from "../floatingcontactus/contactbutton";


const LandingPage = () => {
  return (
    <div style={{ overflowX: 'hidden' }}>
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <ContactButton redirectUrl="https://forms.gle/zYY9kWjyCjJb7Lr3A" />
    </div>
  );
};

export default LandingPage;
