import React from "react";
import { useFormik } from "formik";
import { Box, Button, Grid, Paper, TextField, Typography } from "@mui/material";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { updateAddress } from "../../../store/reducers/formDetailsSlice";

// Define Yup validation schema
const validationSchema = yup.object({
  addressDetails: yup.string().required("Address is required"),
});

const AddressDetails = ({ step, prevStep, nextStep }) => {
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      addressDetails: "", // This will store the user's input
    },
    validationSchema: validationSchema, // Attach validation schema
    onSubmit: (values) => {
      dispatch(updateAddress(values.addressDetails));
      console.log("Form Values:", values); // You can use these values to move to the next step
      nextStep();
    },
  });

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Paper elevation={2}>
          <Box
            sx={{
              width: {
                xs: "90vw",
                md: "700px",
              },
              p: {
                xs: "30px 20px 30px 20px",
                md: "50px 100px 60px 100px",
              },
              bgcolor: "white",
              borderRadius: "22px",
            }}
          >
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontSize: {
                      xs: "18px",
                      md: "23px",
                    },
                    textAlign: "center",
                    fontWeight: 900,
                  }}
                >
                  What's your property address?
                </Typography>
              </Grid>

              {/* Electricity bill input field */}
              <TextField
                sx={{
                  mt: 4,
                  width: {
                    xs: "100%",
                    sm: "350px",
                  },
                }}
                name="addressDetails"
                id="addressDetails"
                placeholder="Street Address"
                variant="outlined"
                value={formik.values.addressDetails}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.addressDetails &&
                  Boolean(formik.errors.addressDetails)
                }
                helperText={
                  formik.touched.addressDetails && formik.errors.addressDetails
                }
              />

              <Grid item xs={12}>
                {step < 6 && (
                  <Button
                    sx={{
                      p: {
                        xs: "10px 120px",
                        sm: "10px 100px",
                        md: "12px 130px",
                      },
                      width: "100%",
                      fontSize: "20px",
                      textTransform: "capitalize",
                      color: "white",
                      fontWeight: 900,
                      mt: 5,
                    }}
                    type="submit"
                    onClick={(e) => {
                      e.preventDefault();
                      formik.handleSubmit();
                    }}
                    variant="contained"
                  >
                    Continue
                  </Button>
                )}
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>
    </>
  );
};

export default AddressDetails;
