import { Box, Container, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import vector from "../../../assets/Group.png";

const Section5 = () => {
  const [activeIndex, setActiveIndex] = useState(1);

  const handlePrev = () => {
    setActiveIndex((prevIndex) => (prevIndex > 1 ? prevIndex - 1 : 3));
  };

  const handleNext = () => {
    setActiveIndex((prevIndex) => (prevIndex < 3 ? prevIndex + 1 : 1));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex < 3 ? prevIndex + 1 : 1));
    }, 2000); // Change slide every 2 seconds

    return () => clearInterval(interval);
  }, []); // Run only once on component mount

  return (
    <Box
      sx={{
        position: "relative",
        overflow: "hidden",
      }}
    >
      <img
        src={vector}
        style={{
          position: "absolute",
          zIndex: -1,
          backgroundColor: "#ebfff0",
          width: "100%",
        }}
      />
      <Box
        sx={{
          padding: {
            md: "80px",
            xs: "20px",
          },
        }}
      >
        <Typography
          sx={{
            mt: {
              md: -3,
              xs: 3,
            },
            mb: {
              md: 1,
              xs: 2,
            },
            fontSize: {
              md: "2.1rem",
              xs: "1.8rem",
            },
          }}
        >
          Our latest blogs
        </Typography>
        <div
          id="controls-carousel"
          className="relative w-full"
          data-carousel="static"
        >
          <div className="relative h-56 overflow-hidden rounded-lg md:h-96">
            {/* Image 1 */}
            <div
              className={`duration-700 ease-in-out ${
                activeIndex === 1 ? "" : "hidden"
              }`}
              data-carousel-item={activeIndex === 1 ? "active" : ""}
            >
              <div className="absolute inset-0 flex items-center justify-center">
                <img
                  src="https://images.unsplash.com/photo-1565085360602-de694f1d7650?q=80&w=2938&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                  className=" object-contain w-full "
                  alt="Carousel 1"
                />
                <div className="absolute bottom-0 left-0 right-0  text-white text-start p-4 text-[18px]">
                  <div className="absolute bottom-0 left-0 right-0  text-white p-4">
                    {/* Wind Energy */}
                  </div>
                </div>
              </div>
            </div>

            {/* Image 2 */}
            <div
              className={`duration-700 ease-in-out ${
                activeIndex === 2 ? "" : "hidden"
              }`}
              data-carousel-item={activeIndex === 2 ? "active" : ""}
            >
              <div className="absolute inset-0 flex items-center justify-center">
                <img
                  src="https://images.unsplash.com/photo-1594389615321-4f50c5d7878c?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                  className="block w-full"
                  alt="Carousel 2"
                />
                <div className="absolute bottom-0 left-0 right-0  text-white  p-4">
                  {/* Text Overlay for Image 2 */}
                </div>
              </div>
            </div>

            {/* Image 3 */}
            <div
              className={`duration-700 ease-in-out ${
                activeIndex === 3 ? "" : "hidden"
              }`}
              data-carousel-item={activeIndex === 3 ? "active" : ""}
            >
              <div className="absolute inset-0 flex items-center justify-center">
                <img
                  src="https://images.unsplash.com/photo-1542601906990-b4d3fb778b09?q=80&w=2813&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                  className="block w-full"
                  alt="Carousel 3"
                />
                <div className="absolute bottom-0 left-0 right-0  text-white p-4">
                  {/* Text Overlay for Image 3 */}
                </div>
              </div>
            </div>
          </div>
          {/* <button
            onClick={handlePrev}
            type="button"
            className="absolute top-0 start-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
            data-carousel-prev
          >
            <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
              <svg
                className="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 1 1 5l4 4"
                />
              </svg>
              <span className="sr-only">Previous</span>
            </span>
          </button> */}
          {/* <button
            onClick={handleNext}
            type="button"
            className="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
            data-carousel-next
          >
            <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
              <svg
                className="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 9 4-4-4-4"
                />
              </svg>
              <span className="sr-only">Next</span>
            </span>
          </button> */}
        </div>
      </Box>
    </Box>
  );
};

export default Section5;
