import { createBrowserRouter } from "react-router-dom";
import Layout from "../layout";
import LandingPage from "../views/landingPage";
import Contact from "../views/contactUs";
import About from "../views/aboutUS";
import AlterCredit from "../views/alterCredit/AlterCredit";
import AlterAdvisory from "../views/alterAdvisory/AlterAdvisory";
import AlterHome from "../views/alterHome";
import PrivacyPolicy from "../views/privacy/index";
import TermsAndConditions from "../views/termsAndCondition";
import MainForm from "../form/page";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <LandingPage replace />,
      },
      {
        path: "/contact-us",
        element: <Contact />,
      },
      {
        path: "/about-us",
        element: <About />,
      },
      {
        path: "/alter-credit",
        element: <AlterCredit />,
      },
      {
        path: "/alter-advisory",
        element: <AlterAdvisory />,
      },
      {
        path: "/alter-home",
        element: <AlterHome />,
      },
      {
        path: "/privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "/terms-and-conditions",
        element: <TermsAndConditions />,
      },
      {
        path: "/form",
        element: <MainForm />,
      },
    ],
  },
]);

export default router;
