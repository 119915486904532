import { Box, Button, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Processing = () => {
  const selector = useSelector(
    (state) => state.formDetailsSlice.date_for_visit
  );
  const selectorDetails = useSelector((state) => state.formDetailsSlice);

  const addData = async () => {
    try {
      console.log(selector, "try");
      const res = await axios.post(
        "https://api.altercarbon.in/zoho-bigin/wizard-form/",
        selectorDetails
      );
      console.log(res);
      
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      addData();
    }, 500);
  }, []);

  const navigate = useNavigate();

  return (
    <>
      <Box
        sx={{
          width: "100%",
          mt: {
            xs: "20px",
            md: "200px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{
              color: "white",
              textAlign: "center",
              fontSize: {
                xs: "1.6rem",
                md: "2rem",
              },
              p: {
                xs: "10px 20px",
                md: "0 300px",
              },
            }}
          >
            {`You have booked a site visit for ${selector} at your address`}
          </Typography>
          <Button
            onClick={() => navigate("/")}
            sx={{
              backgroundColor: "white",
              p: "10px 80px",
              mt: {
                xs: "20px",
                md: "50px",
              },
              fontSize: "1.2rem",
              "&:hover": {
                backgroundColor: "white", // Ensure the background color remains the same on hover
                boxShadow: "none", // Remove the box shadow on hover
              },
            }}
          >
            ok
          </Button>
        </Box>
      </Box>
      {/* <Box
        sx={{
          mt: {
            xs: "50px",
            md: "150px",
          },
        }}
      >
        <div className="form_step_head processing">
          <div className="loader_round"></div>
        </div>
      </Box> */}
    </>
  );
};

export default Processing;
